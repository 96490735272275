require("@fontsource/red-hat-display");
require("@fontsource/red-hat-display/700.css");
require("@fontsource/open-sans");
require("@fontsource/open-sans/700.css");
require("@fontsource/poppins");
require("@fontsource/poppins/600.css");
// import "./src/styles/global.css"

const React = require("react");

// const Layout = require("./src/sections/Layout/index.jsx").default;

exports.wrapPageElement = ({ element, props }) => {
  return <>{element}</>;
};
